<template>
  <div class="content03">
    <p class="message">
      登録されているメールアドレスに<br />メールを送信しました。<br />メールをご確認ください。
    </p>
    <div class="wrap">
      <p class="link">
        <router-link to="/login" :class="txt - link"
          >ログインページ</router-link
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "RemindMailThanks",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" src="@/assets/css/style_form_page.css" scoped></style>
<style lang="css" src="@/assets/css/style_form_parts.css" scoped></style>
